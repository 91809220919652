body {
  margin: 0;
  font-family: 'Nunito', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FBFBFC;
}

* {
  font-family: 'Nunito', serif !important;
  /* word-break: break-word; */
}
.MuiPaper-elevation {
 border-radius: 0px !important;
}
.MuiAutocomplete-listbox {
  height: 20vh;
}
/* .MuiAutocomplete-paper {
  height: '-webkit-fill-available';
} */

.MuiFormLabel-root {
  /* font-size: 18px !important; */
}
.MuiTypography-root {
  font-family: 'Nunito', serif !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiFormControl-root .MuiSelect-outlined {
  min-height: 0 !important;
  height: 12px !important;
}

.MuiList-root li {
  font-size: 16px;
}
.slick-list {
  padding: 5px;
}
.Toastify__toast--success svg {
  fill: #39215E;;
}

/* .Toastify__toast-theme--colored.Toastify__toast--success svg {
  fill: #39215E;
} */

.ql-editor.ql-blank:focus::before {
  content: '';
}
.ql-editor:not(.ql-blank)::before {
  content: '';
}